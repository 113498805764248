<template>
  <div class="min-h-screen pt-4 md:pt-8 flex flex-col items-center justify-center">
    <!-- Snackbar -->
    <div v-if="showSnackbar" class="fixed top-4 left-1/2 transform -translate-x-1/2 bg-ally-blue text-white px-4 py-2 rounded-lg shadow-lg transition-opacity duration-300">
      {{ snackbarMessage }}
    </div>
    <div class="max-w-7xl mx-auto bg-ally-blue rounded-4xl p-6 md:p-10 shadow-lg mx-4 md:mx-8 w-full">
      <!-- Header -->
      <header class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold text-white font-kavoon">Ally</h1>
        <button @click="scrollToFullAccess" class="bg-white text-ally-blue px-4 py-2 rounded-lg text-sm font-semibold hover:bg-gray-100 transition-colors">
          Get Full Access
        </button>
      </header>

      <!-- Main content -->
      <main>
        <h2 class="text-2xl md:text-header-large font-bold text-center text-white mb-4 font-larken-bold">
          Your AI Friend In Addiction Recovery
        </h2>
        <p class="text-center text-small text-white mb-8 max-w-[700px] mx-auto leading-small">
          Ally is here to hear your thoughts, set daily goals, and celebrate your wins, all based on latest research to keep you moving forward.
        </p>

        <div class="bg-white rounded-4xl p-2 md:p-10 md:mb-8 md:mx-12">
          <div class="flex flex-col md:flex-row gap-6 md:h-[450px]">
            <!-- Left panel -->
            <div class="border border-chat-gray rounded-9 p-6 flex-1 flex flex-col justify-top items-top">
              <h1 class="text-medium font-semibold text-ally-blue text-center font-larken-medium  pt-5">
                {{ currentPrompt }}
              </h1>
              <div class="flex flex-col justify-center items-center h-full pt-8 md:pt-0">
                <button @click="toggleListening" :class="['w-24 h-24 rounded-full flex items-center justify-center transition-colors', status === 'Listening...' ? 'bg-red-500 hover:bg-red-600' : 'bg-mic-blue hover:bg-[#385A62]']">
                  <svg v-if="status !== 'Listening...'" xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" viewBox="0 0 60 60" fill="currentColor">
                    <path d="M46.8668 22.3945C45.9156 22.3945 45.1594 23.1506 45.1594 24.1019V27.9557C45.1594 36.5902 38.1348 43.6149 29.5002 43.6149C20.8657 43.6149 13.841 36.5902 13.841 27.9557V24.0775C13.841 23.1262 13.0849 22.3701 12.1337 22.3701C11.1824 22.3701 10.4263 23.1262 10.4263 24.0775V27.9313C10.4263 37.8586 18.0607 46.0297 27.7929 46.9077V52.1031C27.7929 53.0543 28.549 53.8105 29.5002 53.8105C30.4515 53.8105 31.2076 53.0543 31.2076 52.1031V46.9077C40.9154 46.054 48.5742 37.8586 48.5742 27.9313V24.0775C48.5498 23.1506 47.7937 22.3945 46.8668 22.3945Z" fill="#F3F8F8"/>
                    <path d="M29.5002 5.02832C23.5487 5.02832 18.7192 9.85779 18.7192 15.8093V28.2976C18.7192 34.2491 23.5487 39.0785 29.5002 39.0785C35.4517 39.0785 40.2811 34.2491 40.2811 28.2976V15.8093C40.2811 9.85779 35.4517 5.02832 29.5002 5.02832ZM32.6954 21.9803C32.5247 22.6144 31.9637 23.0291 31.3295 23.0291C31.2076 23.0291 31.0856 23.0047 30.9637 22.9803C30.0124 22.712 29.0124 22.712 28.0611 22.9803C27.2806 23.1998 26.5244 22.7364 26.3293 21.9803C26.1098 21.2241 26.5732 20.4436 27.3294 20.2485C28.7684 19.8582 30.2807 19.8582 31.7198 20.2485C32.4515 20.4436 32.8906 21.2241 32.6954 21.9803ZM33.9882 17.2484C33.7687 17.8337 33.232 18.1752 32.6467 18.1752C32.4759 18.1752 32.3296 18.1508 32.1588 18.102C30.4514 17.4679 28.5489 17.4679 26.8415 18.102C26.1098 18.3704 25.2805 17.9801 25.0122 17.2484C24.7439 16.5166 25.1341 15.6873 25.8659 15.4434C28.2074 14.5897 30.7929 14.5897 33.1345 15.4434C33.8662 15.7117 34.2565 16.5166 33.9882 17.2484Z" fill="#F3F8F8"/>
                  </svg>
                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 text-white" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8 7a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V8a1 1 0 00-1-1H8z" clip-rule="evenodd" />
                  </svg>
                </button>
                <p class="text-center text-gray-600 text-lg pt-6 pb-6 md:pb-0">{{ status }}</p>
              </div>

            </div>

            <!-- Right panel -->
            <div class="border border-chat-gray rounded-9 p-6 flex-1">
              <h3 class="text-medium font-semibold text-ally-blue mb-4 font-larken-medium text-center pt-5">Your Chat with <i>Ally</i></h3>
              <div ref="chatHistoryContainer" class="h-64 overflow-y-auto mb-2 md:mb-9">
                <div v-for="(entry, index) in chatHistory" :key="index" class="mb-2">
                  <p class="font-semibold text-sm">👤 You said:</p>
                  <p class="ml-4 text-sm">{{ entry.user }}</p>
                  <p class="font-semibold text-sm">✨ Ally:</p>
                  <p class="ml-4 text-sm">{{ entry.ai }}</p>
                </div>
              </div>
              <div class="border border-chat-gray flex focus-within:outline-none focus-within:ring-1 focus-within:ring-ally-blue rounded-lg">
                <input v-model="chatMessage" @keyup.enter="sendChatMessage" type="text" placeholder="Type your message..." class="flex-grow rounded-s-lg px-4 py-2 text-sm focus:outline-none">
                <button @click="sendChatMessage" class="bg-ally-blue text-white px-2 md:px-4 py-2 rounded-r-lg hover:bg-[#385A62] transition-colors">
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.2974 8.34686L9.4678 4.43206C4.20843 1.7978 2.0498 3.95643 4.68406 9.2158L5.47983 10.8073C5.70849 11.2738 5.70849 11.8135 5.47983 12.28L4.68406 13.8623C2.0498 19.1217 4.19928 21.2803 9.4678 18.6461L17.2974 14.7313C20.8097 12.9751 20.8097 10.103 17.2974 8.34686ZM14.343 12.2251H9.40377C9.02876 12.2251 8.71777 11.9141 8.71777 11.5391C8.71777 11.1641 9.02876 10.8531 9.40377 10.8531H14.343C14.718 10.8531 15.029 11.1641 15.029 11.5391C15.029 11.9141 14.718 12.2251 14.343 12.2251Z" fill="#F3F8F8"/>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
      <!-- Get Started Section -->
      <section ref="fullAccessSection"  class="my-10 md:my-20 text-center px-5 md:px-0 flex flex-col items-center">
        <h2 class="font-bold mb-4 text-ally-blue font-larken-bold text-header-medium md:text-header-large">Get Started with Ally Today</h2>
        <p class="text-extra-small md:text-small mb-6 max-w-xl mx-auto text-custom-grey">
          Sign up for our free mobile app to recieve daily support for you and your loved ones
        </p>
        <div class="mx-3 md:mx-0 bg-white max-w-lg ps-3 md:ps-5 pe-1 border border-ally-blue focus-within:outline-none focus-within:ring-1 focus-within:ring-ally-blue rounded-3xl py-1 w-full md:w-95 mx-3">
          <div class="flex">
            <input
              v-model="contact"
              type="text"
              placeholder="Enter your Email or Phone"
              class="flex-grow py-2 text-gray-700 bg-white rounded-lg focus:outline-none"
              @keyup.enter="joinWaitlist"
            />
            <button
              @click="joinWaitlist"
              class="px-6 py-2 text-white bg-waitlist-green hover:bg-green-600 focus:outline-none rounded-2xl"
            >
              Join Waitlist
            </button>
          </div>
        </div>
      </section>

    <!-- Why Ally Section -->
    <section class="max-w-4xl mx-auto mt-8">
      <h2 class="font-bold mb-4 text-header-medium text-ally-blue font-larken-bold text-center mb-8 md:text-header-large">Why Ally?</h2>
      <div class="max-w-4xl flex flex-row px-6 py-2 md:my-0 md:mb-8 justify-center mb-4 md:mb-0">
        <div class="md:pe-[125px] flex flex-col justify-center pe-2 md:pe-0">
          <h3 class="text-sub-header font-semibold mb-2 font-larken-bold text-custom-grey">Always With You</h3>
          <p class="text-custom-grey text-extra-small md:text-small">
            Whether you're navigating tough decisions or facing challenges, Ally is always ready to listen and offer
            support on your addiction recovery journey—all completely free.
          </p>
        </div>
        <img src="/images/hugging.png" alt="Always with you" class="rounded-lg md:w-[225px] md:h-[225px]  w-[100px] h-[100px] mb-4" />
      </div>

      <div class="max-w-4xl flex flex-row px-6 py-2 md:my-0 md:mb-8 justify-center">
        <img src="/images/lock.png" alt="Always with you" class="rounded-lg md:w-[225px] md:h-[225px]  w-[100px] h-[100px] mb-4 md:me-[125px]" />
        <div class="flex flex-col justify-center ps-2 md:ps-0">
          <h3 class="text-sub-header font-semibold mb-2 font-larken-bold text-custom-grey">Private and Confidential</h3>
          <p class="text-custom-grey text-extra-small md:text-small mb-4 pb-2 md:pb-8">
           Only you have access to your data. Everything you share with Ally is secure, anonymous and kept strictly confidential.
          </p>
        </div>
      </div>

      <div class="max-w-4xl flex flex-row px-6 py-2 md:my-0 md:p-6 md:mb-8 justify-center">
        <div class="md:pe-[125px] flex flex-col justify-center pe-2 md:pe-0">
          <h3 class="text-sub-header font-semibold mb-2 font-larken-bold text-custom-grey">Science-based support</h3>
          <p class="text-custom-grey text-extra-small md:text-small">
            Ally utilizes the latest in Cognitive Behavioral Therapy and addiction recovery research to provide
            personalized exercises and guidance tailored to your unique needs.
          </p>
        </div>
        <img src="/images/brain.png" alt="Always with you" class="rounded-lg md:w-[225px] md:h-[225px]  w-[100px] h-[100px] mb-4" />
      </div>
    </section>

    <!-- Get The Full App Section -->
    <section class="w-full mx-auto mt-8 bg-ally-blue pt-8 pb-4 text-center flex flex-col items-center px-4 md:px-0">
      <h2 class="text-2xl font-bold text-white mb-6 font-larken-bold">Get The Full App</h2>
      <div class="mx-3 md:mx-0 bg-white max-w-lg ps-3 md:ps-5 pe-1 border border-ally-blue focus-within:outline-none focus-within:ring-1 focus-within:ring-ally-blue rounded-3xl py-1 w-full md:w-95 mx-3">
          <div class="flex">
            <input
              v-model="contact"
              type="text"
              placeholder="Enter your Email or Phone"
              class="flex-grow py-2 text-gray-700 bg-white rounded-lg focus:outline-none"
              @keyup.enter="joinWaitlist"
            />
            <button
              @click="joinWaitlist"
              class="px-6 py-2 text-white bg-waitlist-green hover:bg-green-600 focus:outline-none rounded-2xl"
            >
              Join Waitlist
            </button>
          </div>
        </div>
        <div class="max-w-4xl mx-auto mt-8 text-center text-gray-600 text-sm text-white">
          <p>&copy; 2024 Ally. All rights reserved.</p>
        </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import * as Sentry from "@sentry/vue";

export default {
  name: 'App',
  data() {
    return {
      response: '',
      status: 'Tap to share your thoughts',
      chatHistory: [],
      prompts: [
        "What's been on your mind lately 🧠",
        "What's the biggest challenge you're facing in your recovery right now? 🏔️",
        "What's one challenge you've overcome this week? 💪",
        "Who has been a positive influence in your life lately? 🌟",
        "What's a new healthy habit you'd like to develop? 🌱",
        "What's one thing you appreciate about your support system? 🙏",,
        "What's a positive change you've noticed in your life recently? 🌈",
        "What's one thing you've learned about addiction that you wish you knew earlier? 📚",
        "What's one thing you're grateful for in your recovery journey? 🌿",
        "What's one thing you've learned about yourself in recovery? 🧠",
        "What's a goal you're working towards right now? 🎯",
        "What's a positive change you've noticed in your environment recently? 🌿",
        "How have you celebrated a small win this week? 🎉",
        "Who in your support system has been most helpful, and how? 🙏",
        "What's one thing you're looking forward to in your recovery journey? 🌅",
        "Describe a moment when you felt tempted but chose recovery instead. What helped you make that choice? 🛡️"
      ],
      currentPromptIndex: 0,
      journalEntry: '',
      showSnackbar: false,
      snackbarMessage: '',
      mediaRecorder: null,
      mics: [],
      selectedMic: null,
      transcription: '',
      isRecording: false,
      mediaRecorder: null,
      audioChunks: [],
      audio: null,
      isMobile: false,
      chatMessage: '',
    };
  },
  computed: {
    currentPrompt() {
      return this.prompts[this.currentPromptIndex];
    }
  },
  async mounted() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach(track => track.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.mics = devices.filter(device => device.kind === 'audioinput');
      if (this.mics.length > 0) {
        this.selectedMic = this.mics[0].deviceId;
      }
      if (this.audioContext && this.audioContext.state === 'suspended') {
        await this.audioContext.resume();
      }
    } catch (error) {
      console.error('Error enumerating audio devices:', error);
    }
    
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },
  methods: {
    randomizePrompt() {
      this.currentPromptIndex = Math.floor(Math.random() * this.prompts.length);
    },
    previousPrompt() {
      this.currentPromptIndex = (this.currentPromptIndex - 1 + this.prompts.length) % this.prompts.length;
    },
    nextPrompt() {
      this.currentPromptIndex = (this.currentPromptIndex + 1) % this.prompts.length;
    },
    scrollToFullAccess() {
      this.$refs.fullAccessSection.scrollIntoView({ behavior: 'smooth' });
    },
    joinWaitlist() {
      if (this.contact.trim() === '') {
        return;
      }
      console.log("Joining waitlist with contact:", this.contact);
      Sentry.captureMessage("New user joined waitlist", {
        extra: {
          contact: this.contact
        }
      });
      // Clear the "contact" field
      this.contact = '';
      this.showSnackbarMessage('Successfully joined waitlist!');
    },
    showSnackbarMessage(message) {
      this.snackbarMessage = message;
      this.showSnackbar = true;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 3000);
    },
    async toggleListening() {
      if (this.status === 'Listening...') {
        this.status = 'Processing your thoughts...';
        try {
          await this.stopListening();
          // Wait for transcription to be available
          await this.waitForTranscription();
          if (!!this.transcription && this.transcription.trim() !== '') {
            this.status = 'Thinking...';
            await this.sendMessageToBackend(this.transcription.trim());
          } else {
            window.alert('No transcription available');
          }
        } catch (error) {
          console.error('Error in toggleListening:', error);
          this.status = 'Error occurred. Please try again.';
        }
      } else {
        try {
          await this.startListening();
        } catch (error) {
          console.error('Error starting listening:', error);
          this.status = 'Error starting. Please try again.';
        }
      }
    },
    async waitForTranscription() {
      return new Promise((resolve, reject) => {
        const checkTranscription = setInterval(() => {
          if (this.transcription) {
            clearInterval(checkTranscription);
            clearTimeout(timeout);
            resolve();
          }
        }, 100);

        const timeout = setTimeout(() => {
          clearInterval(checkTranscription);
          if (this.transcription) {
            resolve();
          } else {
            reject(new Error('Transcription timeout'));
          }
        }, 10000); // 10 second timeout
      });
    },
    async startListening() {
      try {
        if (this.mediaRecorder !== null) {
          this.mediaRecorder.stop();
        }
        // Stop audio playback
        if (this.audio !== null) {
          this.audio.pause();
          this.audio.currentTime = 0;
        }
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        
        this.mediaRecorder = new MediaRecorder(stream);
        this.audioChunks = [];

        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        };

        this.mediaRecorder.onstop = async () => {
          const audioBlob = new Blob(this.audioChunks, { type: this.audioChunks[0]?.type || 'audio/wav' });
          await this.sendToDeepgram(audioBlob);
        };

        this.status = 'Listening...';
        this.mediaRecorder.start(1000);
        this.isRecording = true;
      } catch (error) {
        console.error('Error accessing microphone:', error);
        this.status = 'Error starting recording';
      }
    },

    async stopListening() {
      return new Promise((resolve, reject) => {
        const cleanup = () => {
          if (this.mediaRecorder && this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
          }
          this.isRecording = false;
        };

        if (this.mediaRecorder && this.isRecording) {
          this.mediaRecorder.onstop = async () => {
            try {
              await this.processAudioAndTranscribe();
              cleanup();
              resolve();
            } catch (error) {
              console.error('Error processing audio:', error);
              cleanup();
              reject(error);
            }
          };
          try {
            this.mediaRecorder.stop();
          } catch (error) {
            console.error('Error stopping mediaRecorder:', error);
            cleanup();
            reject(error);
          }
        } else {
          console.log('MediaRecorder not active or already stopped');
          cleanup();
          resolve();
        }

        // Failsafe: resolve after a timeout even if onstop doesn't fire
        setTimeout(() => {
          if (this.isRecording) {
            console.warn('stopListening timed out, forcing cleanup');
            cleanup();
            reject(new Error('Listening stop timeout'));
          }
        }, 5000); // 5 second timeout
      });
    },
    async processAudioAndTranscribe() {
      if (this.audioChunks.length > 0) {
        const audioBlob = new Blob(this.audioChunks, { type: this.audioChunks[0].type || 'audio/wav' });
        await this.sendToDeepgram(audioBlob);
        
        // Wait for transcription to be available
        let attempts = 0;
        while (!this.transcription && attempts < 30) {
          await new Promise(resolve => setTimeout(resolve, 100));
          attempts++;
        }
        
        if (!this.transcription) {
          throw new Error('Transcription not received in time');
        }
      } else {
        console.warn('No audio data to process');
      }
      Sentry.captureMessage("Microphone button toggled");
    },

    async sendToDeepgram(audioBlob) {
      const url = 'https://api.deepgram.com/v1/listen';

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Token ${process.env.VUE_APP_DEEPGRAM_API_KEY}`,
            'Content-Type': audioBlob.type || 'audio/wav'
          },
          body: audioBlob
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        this.transcription = data.results.channels[0].alternatives[0].transcript;
      } catch (error) {
        console.error('Error sending audio to Deepgram:', error);
        this.status = 'Error processing audio';
        this.transcription = `Error processing audio: ${error.message}`;
        throw error; // Re-throw the error to be caught by the calling function
      }
    },
    async sendMessageToBackend(message) {
      try {
        Sentry.captureMessage("Sending message to backend", {
          extra: {
            message_length: message.length,
            prompt: this.currentPrompt
          }
        });

        this.chatHistory.push({ user: message, ai: "Thinking..." });

        const lastAIMessage = this.chatHistory.length > 0 ? this.chatHistory[this.chatHistory.length - 1].ai : null;
        const questionAsked = this.chatHistory.length === 0 ? this.currentPrompt : null;
        const response = await axios.post('/api/v1/voice_chat', { 
          message: message,
          prompt: questionAsked
        }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        this.response = response.data.response;
        this.chatHistory.pop();
        this.chatHistory.push({ user: message, ai: this.response });
        this.status= 'Tap or chat to share your thoughts';
        this.speakResponse(this.response);

        Sentry.captureMessage("Response received from backend", {
          extra: {
            response: this.response
          }
        });
      } catch (error) {
        console.error('Error in sendMessageToBackend:', error);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          this.response = `Server error: ${error.response.status} - ${error.response.data.error || 'Unknown error'}`;
        } else if (error.request) {
          // The request was made but no response was received
          this.response = 'No response from server. Please check your internet connection.';
        } else {
          // Something happened in setting up the request that triggered an Error
          this.response = `Error: ${error.message}`;
        }
        this.chatHistory.push({ user: message, ai: this.response });
        this.status = 'Error';
      } finally {
        this.status = 'Tap or chat to share your thoughts';
      }
    },
    speakResponse(text) {
      const url = 'https://api.deepgram.com/v1/speak?model=aura-asteria-en'; 
      const options = { 
        method: 'POST', 
        headers: {'Content-Type': 'application/json', 'Authorization': `Token ${process.env.VUE_APP_DEEPGRAM_API_KEY}`,}, 
        body: JSON.stringify({text: text}) 
      }; 
      
      fetch(url, options) 
        .then(response => response.blob())
        .then(blob => {
          this.audio = new Audio();
          this.audio.src = URL.createObjectURL(blob);
          
          this.audio.play()
        })
        .catch(error => console.error('Error:', error));
    },

    async generateJournal() {
      this.status = 'Generating note...';
      this.mediaRecorder.stop();
      // Stop audio playback
      if (this.audio !== null) {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      try {
        const messages = this.chatHistory.map(entry => ({
          user: entry.user,
          ai: entry.ai
        }));
        const response = await axios.post('/api/v1/voice_chat/generate_journal', { messages }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        this.journalEntry = response.data.journal_entry;
        this.scrollToJournal();
      } catch (error) {
        console.error('Error generating journal:', error);
        this.journalEntry = 'Error: ' + (error.response?.data?.error || error.message);
      } finally {
        this.status = 'Tap or chat to share your thoughts';
      }
    },

    // JOURNAL METHODS
    shareJournalEntry() {
      if (navigator.share) {
        navigator.share({
          title: 'My Journal Entry',
          text: this.journalEntry,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing:', error));
      } else {
        navigator.clipboard.writeText(this.journalEntry)
          .then(() => {
            alert('Journal entry copied to clipboard!');
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
      }
    },
    copyJournalEntry() {
      navigator.clipboard.writeText(this.journalEntry)
        .then(() => {
          this.showSnackbarMessage('Copied to clipboard');
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          this.showSnackbarMessage('Failed to copy');
        });
    },
    scrollToJournal() {
      this.$nextTick(() => {
        const container = this.$refs.journalEntryContainer;
        if (container) {
          container.scrollIntoView({ behavior: 'smooth' });
        }
      });
    },
    async sendChatMessage() {
      if (this.chatMessage.trim()) {
        const message = this.chatMessage.trim();
        this.chatMessage = '';
        this.status = 'Thinking...';
        try {
          await this.sendMessageToBackend(message);
        } catch (error) {
          console.error('Error sending chat message:', error);
          this.status = 'Error occurred. Please try again.';
        } finally {
          this.status = 'Tap or chat to share your thoughts';
        }
      }
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}
</style>