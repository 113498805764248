import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css'
import * as Sentry from "@sentry/vue";

document.addEventListener('DOMContentLoaded', () => {
  const app = createApp(App);

  Sentry.init({
    app,
    dsn: "https://9d95d55860dda07c7a96f8c64b3806bc@o4507964056469504.ingest.us.sentry.io/4507964135112704",
    integrations: [
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", "http://192.168.11.53:5100/", "https://allylabs.app"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  app.mount('#app');
});